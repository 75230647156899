// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_sl d_gt d_cr";
export var heroHeaderCenter = "t_gv d_gv d_cr d_dv";
export var heroHeaderRight = "t_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "t_sm d_gq d_cv";
export var heroParagraphCenter = "t_gr d_gr d_cv d_dv";
export var heroParagraphRight = "t_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "t_sn d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "t_sp d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "t_sq d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "t_sr d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "t_ss d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "t_rg q_rg";
export var heroExceptionNormal = "t_rh q_rh";
export var heroExceptionLarge = "t_rj q_rj";
export var Title1Small = "t_qT q_qT q_qn q_qp";
export var Title1Normal = "t_qV q_qV q_qn q_qq";
export var Title1Large = "t_qW q_qW q_qn q_qr";
export var BodySmall = "t_q6 q_q6 q_qn q_qH";
export var BodyNormal = "t_q7 q_q7 q_qn q_qJ";
export var BodyLarge = "t_q8 q_q8 q_qn q_qK";