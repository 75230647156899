import React from 'react';
import HTMLParser from 'html-react-parser';
import clsx from 'clsx';

import { formColor } from '../../../../../helper';
import ButtonEditor from '../../LayoutComponents/ButtonEditor';
import CustomSectionMedia from '../../LayoutComponents/CustomSectionMedia';

import Left from './left';
import Second from './second';
import * as styles from './styles.module.css';

class ComparisonLayout extends React.Component {
  constructor(props) {
    super(props);

    const components = {
      left: Left,
      second: Second,
    };

    let align = 'Left';
    let full = '';
    if (props.section && props.section.styles) {
      align = props.section.styles.align !== undefined ? props.section.styles.align : 'Left';
      full = props.section.styles.full === true ? 'Full' : '';
    }

    this.state = {
      section: props.section,
      align,
      full,
      layout: props.layout,
      components,
    };

    this.createElement = this.createElement.bind(this);
    this.component = this.component.bind(this);
  }

  createElement(item, index, subIndex, itemAlign, highlight) {
    const color0 = {
      color: this.props.themeData.colors[0],
    };

    const color1 = {
      color: this.props.themeData.colors[1],
    };

    const color3 = {
      color: this.props.themeData.colors[3],
    };

    let alignStyle;
    if (subIndex === undefined) {
      alignStyle = `align${this.state.align}`;
    }

    let elem;
    if (item.type.startsWith('HEADINGS/') || item.type.startsWith('PARAGRAPH/')) {
      const id = `${this.state.section._id}_${item.type.startsWith('HEADINGS/') ? 'Title' : 'Paragraph'}_${
        subIndex !== undefined ? subIndex : ''
      }${index}_section`;
      let content;
      let style;
      let styleNames;
      let exception;
      let switchId;

      const componentText = 'comparisonComponentText';
      if (item.type === 'HEADINGS/HEADING-TWO') {
        content = <h2>{HTMLParser(item.text)}</h2>;
        if (subIndex !== undefined) {
          if (this.state.layout === 'second') {
            style = color1;
            style.backgroundColor = color0.color;
            content = <h3 style={style}>{HTMLParser(item.text)}</h3>;
            styleNames = styles.comparisonFloatyBox;
            exception = styles.exceptionWeight;
            switchId = `${this.state.section._id}_comparisonFloatyBox_${index}`;
          } else {
            styleNames = styles.comparisonComponentText;
            exception = highlight ? styles.exceptionWeight : undefined;
            style = highlight ? color0 : color3;
            content = <h2 style={style}>{HTMLParser(item.text)}</h2>;
          }
        } else {
          style = color3;
          styleNames = styles.comparisonMainHeader;
        }
      } else if (item.type === 'HEADINGS/HEADING-THREE') {
        style = this.state.layout === 'left' && highlight ? color0 : color3;
        content = <h3 style={style}>{HTMLParser(item.text)}</h3>;
        styleNames = styles[componentText];
        switchId = 'comparisonComponentText';
      } else if (item.type === 'PARAGRAPH/MEDIUM') {
        content = <span>{HTMLParser(item.text)}</span>;
        styleNames = styles.comparisonSubtitle;
      } else if (item.type === 'PARAGRAPH/PARAGRAPH') {
        content = <span>{HTMLParser(item.text)}</span>;
        styleNames = styles[componentText];
        switchId = 'comparisonComponentText';
      }

      const text = <div className={exception !== undefined ? exception : undefined}>{content}</div>;

      elem = (
        <div
          id={switchId}
          key={id}
          className={`${styleNames}${alignStyle !== undefined ? ` ${styles[alignStyle]}` : ''}`}
          style={style}
        >
          {text}
        </div>
      );
    } else if (item.type === 'IMAGES/IMAGE') {
      const stretch =
        !item.content.round && !item.content.crop && (item.content.width == null || item.content.width === 100);
      const limit = this.props.layout === 'second' && (item.content.round || item.content.crop);
      elem = (
        <div
          id={`${this.state.section._id}_comparisonImageContainer_${index}`}
          key={`${this.state.section._id}_comparisonImage_${subIndex !== undefined ? subIndex : ''}${index}_section`}
          className={clsx(styles.comparisonImageContainer, stretch && styles.stretch, limit && styles.limit)}
        >
          <CustomSectionMedia
            mediaType={item?.content?.icon ? 'ICON' : 'IMAGE'}
            alt={item.content.alt}
            data={item.content}
            images={this.props.images}
            pagePathList={this.props.pagePathList}
            articlePathList={this.props.articlePathList}
            filePathList={this.props.filePathList}
            categoryPathList={this.props.categoryPathList}
            sectionSlugs={this.props.sectionSlugs}
            sizes="(min-width: 768px) 33vw, 66vw"
            loadingStrategy={this.props.isFirst ? 'eager' : undefined}
          />
        </div>
      );
    } else if (item.type.startsWith('BUTTONS/')) {
      const stylename = `${styles[`comparisonBtnWrapper${this.state.layout === 'second' ? 'Second' : ''}`]}${
        this.state.layout === 'second' ? '' : ` ${styles[`align${itemAlign}`]}`
      }`;

      elem = (
        <div key={`${this.state.section._id}_${subIndex !== undefined ? subIndex : ''}${index}`} className={stylename}>
          <ButtonEditor
            buttons={this.props.buttons}
            themeData={this.props.themeData}
            data={item.content}
            fullWidth={this.state.layout === 'second'}
            pagePathList={this.props.pagePathList}
            articlePathList={this.props.articlePathList}
            filePathList={this.props.filePathList}
            categoryPathList={this.props.categoryPathList}
            sectionSlugs={this.props.sectionSlugs}
          />
        </div>
      );
    }

    return elem;
  }

  component(id, compContent, index, align, bg, first, last, layout, highlightIndex, wrap) {
    const SpecificComponent = this.state.components[layout];

    return (
      <SpecificComponent
        key={`${id}_Component_${index}`}
        id={id}
        compContent={compContent}
        index={index}
        align={align}
        bg={bg}
        first={first}
        last={last}
        highlight={index === highlightIndex}
        wrap={wrap}
        images={this.props.images}
      />
    );
  }

  render() {
    const content = [];
    let last;
    let first;
    let highlightIndex;
    this.state.section.data.forEach((item, index) => {
      if (item.type === 'COMPONENT/PERMANENT' && item.active === true) {
        last = index;
        if (this.props.layout === 'left' && first && !highlightIndex) highlightIndex = index;
        if (first === undefined) {
          first = index;
        }
      }
    });

    this.state.section.data.forEach((item, index) => {
      if (item.active === true) {
        if (item.type === 'COMPONENT/PERMANENT') {
          const compContent = [];
          const wrap = item.data[1].active || item.data[2].active;

          item.data.forEach((compItem, compIndex) => {
            if (compItem.active === true) {
              const elem = this.createElement(compItem, index, compIndex, item.align, item.highlight);
              compContent.push(elem);
            }
          });

          let bg;
          if (item.styles.backgroundColor.active) {
            let { backgroundColor } = item.styles;
            if (item.styles.backgroundColor.solid === '' && item.styles.backgroundColor.gradient.from === '')
              backgroundColor = { solid: '#ffffff' };

            bg = formColor(
              backgroundColor,
              false,
              item.styles.backgroundColor.opacity,
              undefined,
              this.props.themeData.colors,
            );
          }

          const component = this.component(
            this.state.section._id,
            compContent,
            index,
            item.align,
            bg,
            first,
            last,
            this.state.layout,
            highlightIndex,
            wrap,
          );
          content.push(component);
        } else if (item.type === 'HEADINGS/HEADING-TWO' || item.type === 'PARAGRAPH/MEDIUM') {
          const elem = this.createElement(item, index, undefined, undefined, undefined);
          content.push(
            <div key={`${this.state.section._id}_Element_${index}`} className="col-12">
              {elem}
            </div>,
          );
        }
      }
    });

    return (
      <div className={`container ${styles[`comparisonContainer${this.state.full}`]}`}>
        <div className={`row no-gutters ${styles.comparisonRow}`}>{content}</div>
      </div>
    );
  }
}

export default ComparisonLayout;
