// extracted by mini-css-extract-plugin
export var alignLeft = "l_pK d_fn d_bF d_dt";
export var alignCenter = "l_bN d_fp d_bC d_dv";
export var alignRight = "l_pL d_fq d_bG d_dw";
export var comparisonContainer = "l_pM d_dW";
export var comparisonContainerFull = "l_pN d_dT";
export var comparisonFloatyBox = "l_h0 d_h0 d_bx d_Y d_cy";
export var comparisonSubtitle = "l_h9 d_h9 d_v d_c4";
export var comparisonRow = "l_h5 d_h5 d_cb d_bH";
export var comparisonMainHeader = "l_h6 d_h6 d_v d_cv";
export var comparisonComponentText = "l_h2 d_h2 d_v";
export var comparisonBtnWrapper = "l_jh d_jh d_d0 d_v d_by";
export var comparisonBtnWrapperSecond = "l_jj d_jj d_v";
export var comparisonImageContainer = "l_jf d_jf d_Y d_bx d_v";
export var stretch = "l_pP";
export var limit = "l_pQ";
export var exceptionWeight = "l_pR q_qQ";