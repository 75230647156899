// extracted by mini-css-extract-plugin
export var tileContent = "v_st d_v d_G d_Y";
export var teamTextLeft = "v_sv d_dt";
export var teamTextCenter = "v_sw d_dv";
export var teamTextRight = "v_sx d_dw";
export var alignLeft = "v_pK d_v d_by d_fn d_bF d_dt";
export var alignCenter = "v_bN d_v d_by d_fp d_bC d_dv";
export var alignRight = "v_pL d_v d_by d_fq d_bG d_dw";
export var teamContainer = "v_sy d_dV";
export var teamContainerFull = "v_sz d_dS";
export var teamRowWrapper = "v_sB d_cb";
export var teamTileWrapper = "v_j2 d_j2 d_Y d_ct";
export var teamTileSquareWrapper = "v_sC d_j3 d_Y d_ct";
export var teamTileRoundWrapper = "v_j3 d_j3 d_Y d_ct";
export var teamTileNoGuttersWrapper = "v_j4 d_j4 d_Y";
export var teamHoverNoGutters = "v_j5 d_j5 d_Z d_v d_G d_by d_bC d_bN d_bl";
export var teamImageText = "v_kb d_kb d_bk d_v d_bx";
export var teamInfoWrapperSquare = "v_sD d_j6";
export var teamInfoWrapperRound = "v_j6 d_j6";
export var teamInfoWrapper = "v_sF d_j7 d_Z";
export var teamInfoWrapperNoGutters = "v_j8 d_j8 d_v d_G d_bC d_bL d_bH d_c6";
export var teamImgWrapper = "v_jY d_jY";
export var teamImgWrapperAlt = "v_jZ d_jZ";
export var teamImgWrapperNoGutters = "v_j9 d_j9";
export var teamHeader = "v_sG d_cv";
export var teamHeaderAlt = "v_sH d_cv";
export var teamHeaderNoGutters = "v_sJ d_cv d_cC";