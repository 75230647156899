// extracted by mini-css-extract-plugin
export var alignDiscLeft = "r_rH d_fn d_bF d_dt";
export var alignLeft = "r_pK d_fn d_bF d_dt";
export var alignDiscCenter = "r_rJ d_fp d_bC d_dv";
export var alignCenter = "r_bN d_fp d_bC d_dv";
export var alignDiscRight = "r_rK d_fq d_bG d_dw";
export var alignRight = "r_pL d_fq d_bG d_dw";
export var footerContainer = "r_rL d_dV d_bV";
export var footerContainerFull = "r_rM d_dS d_bV";
export var footerHeader = "r_kd d_kd";
export var footerTextWrapper = "r_rN d_v";
export var footerDisclaimerWrapper = "r_kl d_kl d_cj";
export var badgeWrapper = "r_rP d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "r_rQ undefined";
export var footerDisclaimerLeft = "r_rR undefined";
export var verticalTop = "r_rS d_by d_bH d_bM d_bK";
export var firstWide = "r_rT";
export var disclaimer = "r_rV d_by d_bH";
export var socialDisclaimer = "r_rW";
export var left = "r_rX";
export var wide = "r_rY d_cx";
export var right = "r_rZ d_bJ";
export var line = "r_fj d_fk d_ct";
export var badgeDisclaimer = "r_r0 d_bB d_bN d_bH";
export var badgeContainer = "r_r1 d_by d_bG d_bN";
export var badge = "r_r2";
export var padding = "r_r3 d_c6";
export var end = "r_r4 d_bG";
export var linkWrapper = "r_r5 d_dz";
export var link = "r_mB d_dz";
export var colWrapper = "r_r6 d_cw";
export var consent = "r_f d_f d_bB d_bN";
export var media = "r_r7 d_bw d_dx";
export var itemRight = "r_r8";
export var itemLeft = "r_r9";
export var itemCenter = "r_sb";
export var exceptionWeight = "r_pR q_qQ";